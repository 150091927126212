import { getUserPermissions } from "auth/authService";

export const userPermissions = getUserPermissions() ? getUserPermissions()?.split(",") : [];

export const PERMISSIONS = [
  "Permissions.User.View",
  "Permissions.User.Insert",
  "Permissions.User.Edit",
  "Permissions.User.Delete",
  "Permissions.User.ViewAllUser",
  "Permissions.Role.View",
  "Permissions.Role.Insert",
  "Permissions.Role.Edit",
  "Permissions.Role.Delete",
  "Permissions.Product.Insert",
  "Permissions.Product.Edit",
  "Permissions.Product.Delete",
  "Permissions.Product.View",
  "Permissions.Product.ViewAllDetails",
  "Permissions.CompanyProduct.View",
  "Permissions.Coupon.View",
  "Permissions.Coupon.Insert",
  "Permissions.Coupon.Edit",
  "Permissions.Order.View",
  "Permissions.Category.Insert",
  "Permissions.Category.Edit",
  "Permissions.Category.Delete",
  "Permissions.Product.Best",
  "Permissions.Order.ChangeStatus",
  "Permissions.Category.Best",
  "Permissions.Affiliate.View",
  "Permissions.Affiliate.Insert",
  "Permissions.Affiliate.Edit",
  "Permissions.Attribute.Insert",
  "Permissions.Attribute.Edit",
  "Permissions.City.Insert",
  "Permissions.City.Edit",
  "Permissions.City.Delete",
  "Permissions.Email.View",
  "Permissions.Email.Edit",
  "Permissions.Reports.Export",
  "Permissions.Request.View",
  "Permissions.Request.Complete",
  "Permissions.Request.Toggle",
  "Permissions.ScreenItem.Insert",
  "Permissions.ScreenItem.Edit",
  "Permissions.ScreenItem.Delete",
  "Permissions.Tag.Insert",
  "Permissions.Tax.View",
  "Permissions.Tax.Edit",
  "Permissions.Zone.Insert",
  "Permissions.Zone.Edit",
  "Permissions.Zone.Delete",
  "Permissions.Product.IsHot",
  "Permissions.Statistics.View",
  "Permissions.Currency.Insert",
  "Permissions.Currency.Delete",
  "Permissions.Configuration.Update",
  "Permissions.Configuration.Get",
  "Permissions.ProductItem.Delete",
  "Permissions.ProductItem.Insert",
  "Permissions.Product.Approve",
  "Permissions.User.Active",
  "Permissions.Vendor.Edit",
  "Permissions.Vendor.Insert",
  "Permissions.Vendor.View",
  "Permissions.Ticket.Edit",
  "Permissions.Message.Insert",
  "Permissions.Vendor.ToggleApproved",
  "Permissions.Pages.Edit",
  "Permissions.Pages.Insert",
  "Permissions.Brand.Insert",
  "Permissions.Brand.Delete",
  "Permissions.Brand.Edit",
  "Permissions.Ticket.Insert",
  "Permissions.Ticket.Close",
];
