import React from "react";
import { Navigate } from "react-router-dom";
import sprite from "./assets/images/icons/sprite.svg";

import { Icon, SvgIcon } from "@mui/material";
import Crop32Icon from "@mui/icons-material/Crop32";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { PaymentRounded } from "@mui/icons-material";
import PaymentsHistory from "pages/payments-history";

const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Products = React.lazy(() => import("./pages/products"));
const MainSlider = React.lazy(() => import("./pages/main-slider"));
const ReturnRequests = React.lazy(() => import("./pages/return requests"));
const WithdrawRequests = React.lazy(() => import("./pages/withdraw requests"));
const UserWithdrawRequests = React.lazy(() => import("./pages/user withdraw requests"));
const Currencies = React.lazy(() => import("./pages/currencies"));
const Tax = React.lazy(() => import("./pages/tax"));
const Reports = React.lazy(() => import("./pages/reports"));
const Coupons = React.lazy(() => import("./pages/coupons"));
const Tickets = React.lazy(() => import("./pages/tickets"));
const Orders = React.lazy(() => import("./pages/orders"));
const Affiliate = React.lazy(() => import("./pages/affiliate"));
const OrderDetails = React.lazy(() => import("./pages/orders/orderDetails"));
const Emails = React.lazy(() => import("./pages/emails"));
const Configurations = React.lazy(() => import("./pages/configurations"));
const Pages = React.lazy(() => import("./pages/pages"));
const Zones = React.lazy(() => import("./pages/zones"));
const Users = React.lazy(() => import("./pages/users"));
const Vendors = React.lazy(() => import("./pages/vendors"));
const Suppliers = React.lazy(() => import("./pages/suppliers"));
const Roles = React.lazy(() => import("./pages/roles"));
const CreateProduct = React.lazy(() => import("./pages/products/CreateProduct"));
const Brands = React.lazy(() => import("./pages/brands"));
const Tags = React.lazy(() => import("./pages/tags"));
const EditProduct = React.lazy(() => import("./pages/products/EditProduct"));
const ProductDetails = React.lazy(() => import("./pages/products/ProductDetails"));
const GoldKarats = React.lazy(() => import("./pages/gold-karats"));
const Categories = React.lazy(() => import("./pages/categories"));
const Attributes = React.lazy(() => import("./pages/attributes"));
const SignIn = React.lazy(() => import("./auth/sign-in"));
const Logout = React.lazy(() => import("./auth/log-out"));
const ForgetPassword = React.lazy(() => import("./auth/reset-password/cover"));
const SocialMedia = React.lazy(() => import("pages/social-media"));
const MainVideo = React.lazy(() => import("./pages/main-video"));

const TaxIcon = (props) => (
  <SvgIcon {...props}>
    <svg className="icon">
      <use href={sprite + "#tax"}></use>
    </svg>
  </SvgIcon>
);

const routes = [
  {
    type: "collapse",
    name: "dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  {
    type: "title",
    name: "User Withdraw Requests",
    key: "dashboard",
    route: "/your-withdraw-requests",
    component: <UserWithdrawRequests />,
    protected: false,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">inventory</Icon>,
    collapsible: true,
    items: [
      {
        type: "collapse",
        name: "Products",
        key: "products",
        icon: <Icon fontSize="small">inventory</Icon>,
        route: "/products",
        component: <Products />,
        protected: true,
        requiredScopes: ["Permissions.Product.View"],
      },
      {
        type: "collapse",
        name: "Gold Karats",
        key: "gold-karats",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/gold-karats",
        component: <GoldKarats />,
        protected: true,
      },
      {
        type: "collapse",
        name: "attributes",
        key: "attributes",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/attributes",
        component: <Attributes />,
        protected: true,
      },
      {
        type: "collapse",
        name: "categories",
        key: "categories",
        icon: <Icon fontSize="small">category</Icon>,
        route: "/categories",
        component: <Categories />,
        protected: true,
      },
      {
        type: "collapse",
        name: "brands",
        key: "brands",
        route: "/brands",
        component: <Brands />,
        protected: true,
      },
      {
        type: "collapse",
        name: "tags",
        key: "tags",
        icon: <Icon fontSize="small">style</Icon>,
        route: "/tags",
        component: <Tags />,
        protected: true,
      },
    ],
  },
  {
    type: "title",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/products",
    component: <Products />,
    protected: true,
    requiredScopes: ["Permissions.Product.View"],
  },
  {
    type: "title",
    name: "Gold Karats",
    key: "gold-karats",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/gold-karats",
    component: <GoldKarats />,
    protected: true,
  },
  {
    type: "title",
    name: "Attributes",
    key: "attributes",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/attributes",
    component: <Attributes />,
    protected: true,
  },
  {
    type: "title",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <Categories />,
    protected: true,
  },

  {
    type: "collapse",
    name: "reports",
    key: "reports",
    icon: <Icon fontSize="small">leaderboard</Icon>,
    route: "/reports",
    component: <Reports />,
    protected: true,
    requiredScopes: ["Permissions.Reports.Export"],
  },
  {
    type: "title",
    name: "Brands",
    key: "brands",
    icon: <Icon fontSize="small">style</Icon>,
    route: "/brands",
    component: <Brands />,
    protected: true,
  },
  {
    type: "title",
    name: "Tags",
    key: "tags",
    icon: <Icon fontSize="small">style</Icon>,
    route: "/tags",
    component: <Tags />,
    protected: true,
  },
  {
    type: "collapse",
    name: "orders",
    key: "orders",
    icon: <FormatListNumberedIcon />,
    route: "/orders",
    component: <Orders />,
    protected: true,
    requiredScopes: ["Permissions.Order.View"],
  },
  {
    type: "collapse",
    name: "Payments History",
    key: "payments-history",
    icon: <PaymentRounded />,
    route: "/payments-history",
    component: <PaymentsHistory />,
    protected: true,
    requiredScopes: ["Permissions.Order.View"],
  },
  {
    type: "collapse",
    name: "affiliate",
    key: "affiliate",
    icon: <Icon>campaign</Icon>,
    route: "/affiliate",
    component: <Affiliate />,
    protected: true,
    requiredScopes: ["Permissions.Affiliate.View"],
  },
  {
    type: "title",
    name: "return_request",
    key: "return-requests",
    route: "/return-requests",
    component: <ReturnRequests />,
    protected: true,
    requiredScopes: ["Permissions.Request.View"],
  },
  {
    type: "title",
    name: "withdraw_request",
    key: "withdraw-requests",
    route: "/withdraw-requests",
    component: <WithdrawRequests />,
    protected: true,
    requiredScopes: ["Permissions.Request.View"],
  },
  {
    type: "title",
    name: "Supported Currencies",
    key: "currencies",
    icon: <CurrencyExchangeIcon />,
    route: "/currencies",
    component: <Currencies />,
    protected: true,
  },
  {
    type: "collapse",
    name: "coupons",
    key: "coupons",
    icon: <Icon fontSize="small">discount</Icon>,
    route: "/coupon",
    component: <Coupons />,
    protected: true,
    requiredScopes: ["Permissions.Coupon.View"],
  },
  {
    type: "collapse",
    name: "requests",
    key: "requests",
    icon: <Icon fontSize="small">move_to_inbox</Icon>,
    collapsible: true,
    items: [
      {
        type: "collapse",
        name: "return_requests",
        key: "return-requests",
        route: "/return-requests",
        component: <ReturnRequests />,
        protected: true,
        requiredScopes: ["Permissions.Request.View"],
      },
      {
        type: "collapse",
        name: "withdraw_requests",
        key: "withdraw-requests",
        route: "/withdraw-requests",
        component: <WithdrawRequests />,
        protected: true,
        requiredScopes: ["Permissions.Request.View"],
      },
    ],
  },
  {
    type: "title",
    name: "Main Slider",
    key: "main-slider",
    icon: <Icon fontSize="small">screenshot_monitor</Icon>,
    route: "/main-slider",
    component: <MainSlider />,
    protected: true,
  },
  {
    type: "title",
    name: "Main Video",
    key: "main-video",
    icon: <Icon fontSize="small">screenshot_monitor</Icon>,
    route: "/main-video",
    component: <MainVideo />,
    protected: true,
  },
  {
    type: "title",
    name: "Social Media",
    key: "social-media",
    icon: <Icon fontSize="small">screenshot_monitor</Icon>,
    route: "/social-media",
    component: <SocialMedia />,
    protected: true,
  },
  {
    type: "title",
    name: "Emails",
    key: "emails",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/emails",
    component: <Emails />,
    protected: true,
    requiredScopes: ["Permissions.Email.View"],
  },
  {
    type: "title",
    key: "orderDetails",
    route: "/orders/:id",
    component: <OrderDetails />,
    protected: true,
    requiredScopes: ["Permissions.Order.View"],
  },
  {
    type: "title",
    name: "Configurations",
    key: "configurations",
    // icon: <Icon fontSize="small">settings</Icon>,
    route: "/configurations",
    component: <Configurations />,
    protected: true,
    requiredScopes: ["Permissions.Configuration.Get"],
  },
  {
    type: "title",
    name: "Pages",
    key: "pages",
    // icon: <Icon>article</Icon>,
    route: "/pages",
    component: <Pages />,
    protected: true,
  },
  {
    type: "title",
    name: "Zones",
    key: "zones",
    icon: <Crop32Icon />,
    route: "/zones",
    component: <Zones />,
    protected: true,
  },
  {
    type: "title",
    name: "Vendors",
    key: "vendors",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/vendors",
    component: <Vendors />,
    protected: true,
    requiredScopes: ["Permissions.Vendor.View"],
  },
  {
    type: "title",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
    protected: true,
    requiredScopes: ["Permissions.User.View"],
  },
  {
    type: "title",
    name: "Suppliers",
    key: "Suppliers",
    route: "/suppliers",
    component: <Suppliers />,
    protected: true,
    requiredScopes: [],
  },
  {
    type: "title",
    name: "Roles",
    key: "roles",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/roles",
    component: <Roles />,
    protected: true,
    requiredScopes: ["Permissions.Role.View"],
  },
  {
    type: "title",
    name: "Add Product",
    key: "add-product",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/products/add",
    component: <CreateProduct />,
    protected: true,
    requiredScopes: ["Permissions.Product.Insert"],
  },
  {
    type: "title",
    name: "Products",
    key: "product-edit-redirect",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/products/edit",
    component: <Navigate to="/products" />,
    protected: true,
  },
  {
    type: "title",
    name: "Products",
    key: "product-details-redirect",
    icon: <Icon fontSize="small">product</Icon>,
    route: "/products/product",
    component: <Navigate to="/products" />,
    protected: true,
  },
  {
    type: "title",
    name: "Edit Product",
    key: "edit-product",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/products/edit/:id",
    component: <EditProduct />,
    protected: true,
    requiredScopes: ["Permissions.Product.Edit"],
  },
  {
    type: "title",
    name: "Product Details",
    key: "product-details",
    icon: <Icon fontSize="small">details</Icon>,
    route: "/products/product/:id",
    component: <ProductDetails />,
    protected: true,
    requiredScopes: ["Permissions.Product.View"],
  },
  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    protected: false,
  },
  {
    type: "title",
    name: "logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/logout",
    component: <Logout />,
    protected: true,
  },
  {
    type: "title",
    name: "forget password",
    key: "forget-password",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/forget-password",
    component: <ForgetPassword />,
    protected: false,
  },
  {
    type: "collapse",
    name: "users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    collapsible: true,
    items: [
      {
        type: "collapse",
        name: "users",
        key: "users",
        icon: <Icon fontSize="small">people</Icon>,
        route: "/users",
        component: <Users />,
        protected: true,
        requiredScopes: ["Permissions.User.View"],
      },
      {
        type: "collapse",
        name: "vendors",
        key: "vendors",
        icon: <Icon fontSize="small">store</Icon>,
        route: "/vendors",
        component: <Vendors />,
        protected: true,
        requiredScopes: ["Permissions.Vendor.View"],
      },
      {
        type: "collapse",
        name: "suppliers",
        key: "suppliers",
        route: "/suppliers",
        component: <Vendors />,
        protected: true,
        requiredScopes: [],
      },
      {
        type: "collapse",
        name: "roles",
        key: "roles",
        icon: <Icon fontSize="small">security</Icon>,
        route: "/roles",
        component: <Roles />,
        protected: true,
        requiredScopes: ["Permissions.Role.View"],
      },
    ],
  },
  {
    type: "collapse",
    name: "configurations",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    collapsible: true,
    items: [
      {
        type: "collapse",
        name: "configurations",
        key: "configurations",
        icon: <Icon fontSize="small">settings</Icon>,
        route: "/configurations",
        component: <Configurations />,
        protected: true,
        requiredScopes: ["Permissions.Configuration.Get"],
      },
      {
        type: "collapse",
        name: "pages",
        key: "pages",
        icon: <Icon>article</Icon>,
        route: "/pages",
        component: <Pages />,
        protected: true,
      },
      {
        type: "collapse",
        name: "zones",
        key: "zones",
        icon: <Crop32Icon />,
        route: "/zones",
        component: <Zones />,
        protected: true,
      },
      {
        type: "collapse",
        name: "emails",
        key: "emails",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/emails",
        component: <Emails />,
        protected: true,
        requiredScopes: ["Permissions.Email.View"],
      },
      {
        type: "collapse",
        name: "currencies",
        key: "currencies",
        icon: <CurrencyExchangeIcon />,
        route: "/currencies",
        component: <Currencies />,
        protected: true,
      },
      {
        type: "collapse",
        name: "tax",
        key: "tax",
        icon: <TaxIcon />,
        route: "/tax",
        component: <Tax />,
        protected: true,
        requiredScopes: ["Permissions.Tax.View"],
      },
      {
        type: "collapse",
        name: "main_slider",
        key: "main-slider",
        icon: <Icon fontSize="small">screenshot_monitor</Icon>,
        route: "/main-slider",
        component: <MainSlider />,
        protected: true,
      },
      {
        type: "title",
        name: "Main Video",
        key: "main-video",
        icon: <Icon fontSize="small">screenshot_monitor</Icon>,
        route: "/main-video",
        component: <MainVideo />,
        protected: true,
      },
      {
        type: "collapse",
        name: "Social Media",
        key: "social-media",
        icon: <Icon fontSize="small">social media</Icon>,
        route: "/social-media",
        component: <SocialMedia />,
        protected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "tickets",
    key: "tickets",
    icon: <Icon fontSize="small">support_agent</Icon>,
    route: "/tickets",
    component: <Tickets />,
    protected: true,
  },
  {
    type: "title",
    name: "Tax",
    key: "tax",
    icon: <TaxIcon />,
    route: "/tax",
    component: <Tax />,
    protected: true,
    requiredScopes: ["Permissions.Tax.View"],
  },
];

export default routes;
