import { useState, useEffect, useMemo, Suspense, useLayoutEffect } from "react";
import colors from "assets/theme/base/colors";
import { withTranslation } from "react-i18next";
import { getJwt } from "auth/authService";
import { useNavigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 1000, //1min
      cacheTime: 5 * 1000, //5 min
    },
  },
});

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setDirection,
} from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

import LoadingScreen from "react-loading-screen";

import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { splitCategories, cs } from "util/categories";

function App({ i18n }) {
  
  const [controller, dispatch] = useMaterialUIController();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuth, setIsAuth] = useState(() => {
    return getJwt() ? true : false;
  });

  useLayoutEffect(() => {
    setDirection(dispatch, i18n.language === "ar" ? "rtl" : "ltr");
  }, [i18n.language]);

  //redirect if user not logged in
  useLayoutEffect(() => {
    // getJwt() ? window.location = "/Authentication/sign-in" : null;
  }, [Cookies]);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.protected) {
        return (
          <Route
            path={route.route}
            element={
              <ProtectedRoute isAuth={isAuth} path={route.route} element={route.component} />
            }
            key={route.key}
          ></Route>
        );
      } else {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          
          <CssBaseline />

          {layout === "dashboard" && isAuth && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {/* {layout === "vr" && <Configurator />} */}
          <Suspense
            fallback={
              <LoadingScreen
                loading={true}
                bgColor="#FFFFFFAA"
                spinnerColor={colors.primary.main}
                textColor="#676767"
                children=""
              />
            }
          >
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheProvider>
  ) : (
    <QueryClientProvider client={queryClient}>

      <ToastContainer limit={3} />

      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && isAuth && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Suspense
            fallback={
            <LoadingScreen
              loading={true}
              bgColor="#FFFFFFAA"
              spinnerColor={colors.primary.main}
              textColor="#676767"
              children=""
            />
          }
        >
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default withTranslation()(App);
