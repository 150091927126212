/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, info } = colors;

const checkbox = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: pxToRem(20),
        height: pxToRem(20),
        color: transparent.main,
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: pxToRem(5.6),
      },
      "&:hover": {
        backgroundColor: transparent.main,
      },
      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${info.main} !important`,
      },
    },
    colorPrimary: {
      color: borderColor,
      "&.Mui-checked": {
        color: info.main,
        "& .MuiSvgIcon-root": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            info.main,
            info.main
          )}`,
          borderColor: info.main,
        },
      },

      "&.MuiCheckbox-indeterminate": {
        color: colors.primary.main,
        "& .MuiSvgIcon-root": {
          // backgroundImage: `none`,
          borderColor: "transparent",
        },
      },
    },
    colorSecondary: {
      color: borderColor,
      "& .MuiSvgIcon-root": {
        color: info.main,
        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            info.main,
            info.main
          )}`,
          borderColor: info.main,
        },
        "&.MuiCheckbox-indeterminate": {
          // backgroundImage: `url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' standalone='no'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='50.000000pt' height='50.000000pt' viewBox='0 0 50.000000 50.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'%3E%3Cpath d='M62 437 c-21 -22 -22 -33 -22 -188 0 -221 -12 -209 211 -209 221 0 209 -12 209 211 0 221 12 209 -211 209 -160 0 -166 -1 -187 -23z m308 -187 c0 -19 -7 -20 -120 -20 -113 0 -120 1 -120 20 0 19 7 20 120 20 113 0 120 -1 120 -20z'/%3E%3C/g%3E%3C/svg%3E")`,
          borderColor: "#ffffff",
        },
      },
    },
  },
};

export default checkbox;
