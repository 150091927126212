import MDTypography from "components/MDTypography";

export default function PageContainer({ children, title }) {
  return (
    <div className="page-body">
      {title && (
        <div className="title-header">
          <MDTypography variant="h3">{title}</MDTypography>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">{children}</div>
        </div>
      </div>
    </div>
  );
}
