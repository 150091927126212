import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import { Grid, Switch, TextField } from "@mui/material";

const DataObjForm = ({ dataObj, setDataObj, handleTogglePaymentMethod }) => {
  const { t } = useTranslation();
  return (
    <MDBox mx={1}>
      <MDBox mb={2}>
        <MDInput
          autoFocus
          value={dataObj.withdrawAmount}
          onChange={(e) =>
            setDataObj((oldForms) => ({
              ...oldForms,
              withdrawAmount: e.target.value,
            }))
          }
          margin="dense"
          id="withdraw-amount"
          label={t("send_withdraw_data_amount")}
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          variant="standard"
        />
      </MDBox>
      {/* paypal */}
      <MDBox mt={4}>
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="subtitle1">
            {t("send_withdraw_data_paypal_method_title")}
          </MDTypography>
          <Switch
            checked={dataObj.withdrawPayPal}
            onChange={(e) => handleTogglePaymentMethod(!dataObj.withdrawPayPal, "withdrawPayPal")}
          />
        </MDBox>
        {dataObj.withdrawPayPal && (
          <>
            <Grid container columnSpacing={10}>
              <Grid item md={6} xs={12} mb={1}>
                <TextField
                  value={dataObj.withdrawPayPal.name}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawPayPal: {
                        ...oldForms.withdrawPayPal,
                        name: e.target.value,
                      },
                    }))
                  }
                  label={t("name")}
                  id="name"
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={dataObj.withdrawPayPal.email}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawPayPal: {
                        ...oldForms.withdrawPayPal,
                        email: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="email"
                  label={t("email")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
            </Grid>
            <MDBox mt={1}>
              <TextField
                value={dataObj.withdrawPayPal.phoneNumber}
                onChange={(e) =>
                  setDataObj((oldForms) => ({
                    ...oldForms,
                    withdrawPayPal: {
                      ...oldForms.withdrawPayPal,
                      phoneNumber: e.target.value,
                    },
                  }))
                }
                margin="dense"
                id="phoneNumber"
                label={t("phoneNumber")}
                helperText={`${t("ex")}: +9710000000`}
                fullWidth
                variant="standard"
                type="text"
              />
            </MDBox>
          </>
        )}
      </MDBox>
      {/* bank */}
      <MDBox mt={4}>
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography variant="subtitle1">
            {t("send_withdraw_data_bank_method_title")}
          </MDTypography>
          <Switch
            checked={dataObj.withdrawBank}
            onChange={(e) => handleTogglePaymentMethod(!dataObj.withdrawBank, "withdrawBank")}
          />
        </MDBox>
        {dataObj.withdrawBank && (
          <>
            <Grid container columnSpacing={10}>
              <Grid item md={6} xs={12} mb={1}>
                <TextField
                  value={dataObj.withdrawBank.accountHolderName}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        accountHolderName: e.target.value,
                      },
                    }))
                  }
                  label={t("send_withdraw_data_bank_account_holder_name")}
                  id="accountHolderName"
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={dataObj.withdrawBank.bankName}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        bankName: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="bankName"
                  label={t("send_withdraw_data_bank_bank_name")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10}>
              <Grid item md={6} xs={12} mb={1}>
                <TextField
                  value={dataObj.withdrawBank.city}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        city: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="city"
                  label={t("send_withdraw_data_bank_city")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={dataObj.withdrawBank.swiftCode}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        swiftCode: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="swiftCode"
                  label={t("send_withdraw_data_bank_swift_code")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={10}>
              <Grid item md={6} xs={12} mb={1}>
                <TextField
                  value={dataObj.withdrawBank.accountNumber}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        accountNumber: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="accountNumber"
                  label={t("send_withdraw_data_bank_account_number")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={dataObj.withdrawBank.iban}
                  onChange={(e) =>
                    setDataObj((oldForms) => ({
                      ...oldForms,
                      withdrawBank: {
                        ...oldForms.withdrawBank,
                        iban: e.target.value,
                      },
                    }))
                  }
                  margin="dense"
                  id="iban"
                  label={t("send_withdraw_data_bank_iban")}
                  fullWidth
                  variant="standard"
                  type="text"
                />
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </MDBox>
  );
};

export default DataObjForm;
