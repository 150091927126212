
//MD comonents
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

//my components
import TableLoader from "components/TableLoader";
import PermissionsGate from "auth/PermissionGate";
import PageContainer from "components/PageContainer";

import { useState } from "react";
import { getWalletHistory } from "./service";
import { useQuery } from "react-query";
import Pagination from "components/MyPagination";
import { Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import styled from "@emotion/styled";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const PaymentsHistory = () => {

    const [page,setPage] = useState(1);

    const {
        data: payments,
        isLoading,
        isSuccess
    } = useQuery(['wallet-history', page], () => getWalletHistory(page,10),{
        keepPreviousData: true
    })

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PermissionsGate requiredScopes={[]}>
        <PageContainer
          title={
            <MDBox display="flex" alignItems="center" gap={1}>
              <MDTypography variant="h3">Payments History</MDTypography>
            </MDBox>
          }
        >
          <div className="card">
            <div className="card-body">
              <div>
                <div className="table-responsive table-desi table-product">
                    { isLoading && <TableLoader /> }
                    { isSuccess &&
                        <table className="table table-1d all-package user-table coupon-list-table table table-striped">
                        <thead className="">
                          <tr>
                            <th>Date</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.walletHistory.map((payment) => (
                            <tr key={payment.createdAt}>
                              <td>
                                <h5 style={{fontSize: 14}}>{(new Date(payment.createdAt)).toLocaleString()}</h5>
                              </td>
                              <td>
                                <p style={{color: '#666'}}>{payment.note}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    } 
                </div>
              </div>
            </div>

            {/* pagination start */}
            <div className="pagination-box">
              <nav className="ms-auto me-auto" aria-label="...">
                {isSuccess && (
                  <Pagination
                    setPage={setPage}
                    currentPage={payments.paginationInfo.pageNo}
                    totalPages={payments.paginationInfo.totalPages}
                  />
                )}
              </nav>
            </div>
            {/* pagination end */}
          </div>
          {/* Container-fluid Ends */}
        </PageContainer>
      </PermissionsGate>
    </DashboardLayout>
  );
};

export default PaymentsHistory;
