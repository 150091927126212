import axios from "axios";
import { toast } from "react-toastify";
import { getJwt } from "auth/authService";

export const baseURL = "http://alshahbatech-001-site6.atempurl.com/api";

axios.defaults.baseURL = baseURL;

let lang = localStorage.getItem("i18nextLng") || "en";

axios.defaults.headers.common["Authorization"] = `Bearer ${getJwt()}`;
axios.defaults.headers.put["Content-Type"] = "application/json-patch+json";

//set language
axios.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    lang,
  },
  params: {
    ...config.params,
    lang,
  },
}));

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {

    if (error.response?.status === 401) {
      toast.error("Your session is expired please refresh the page and re-sign in.");
    }
    if (error.response?.status === 403) {
      toast.error("You Are not authorized to do this action.");
    }
    if (error.response?.status === 404) {
      toast.error("Not Found!");
    }

    const isExpectedError =
      error.response && error.response?.status >= 400 && error.response?.status < 500;

    error.isExpectedError = isExpectedError;

    if (!isExpectedError) {
      console.log("An unexpected error occurrred.");
    }
    if (
      isExpectedError &&
      error.response?.status !== 404 &&
      error.response?.status !== 403
    )
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  changeLangRespons: (newLang) => {
    lang = newLang;
  },
  baseURL
};
