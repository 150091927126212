import { useState } from "react";
import uuid from "react-uuid";
import "assets/css/accordion.css";
import { Icon } from "@mui/material";

const CSSAccordion = ({ header, content, itemsLength = 1 }) => {
  const uid = uuid();
  const [opened, setOpened] = useState();

  return (
    <div className="tabs" style={{ boxShadow: "none" }}>
      <div className="tab" style={{ overflowX: "hidden" }}>
        <input
          type="checkbox"
          className="accordionCheck"
          id={uid}
          onChange={(e) => setOpened(e.target.checked)}
        />
        <label
          style={{ background: "transparent", padding: "0", position: "relative" }}
          className="tab-label sidenav-collapse-label"
          htmlFor={uid}
        >
          {header}
          <Icon className="sidenav-arrow">chevron_right</Icon>
        </label>
        <div
          className="tab-content sidenav-collapse-content"
          style={{ maxHeight: opened ? 60 * itemsLength : 0, overflowY: "hidden" }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default CSSAccordion;
