import { useEffect } from "react";
import { t } from "i18next";

import PermissionsGate from "auth/PermissionGate";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import CSSAccordion from "./SidenavAccordion";
import { hasOnePermission } from "auth/PermissionGate/index";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */

    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    // handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      noCollapse,
      key,
      href,
      route,
      requiredScopes = [],
      items,
      collapsible,
    }) => {
      let returnValue;

      let collapsibleItemRequiredScopes = [];
      if (collapsible) {
        items.forEach((item) => {
          item.requiredScopes?.map((scop) => collapsibleItemRequiredScopes.push(scop));
        });
      }

      if (type === "collapse") {
        returnValue = collapsible ? (
          hasOnePermission(collapsibleItemRequiredScopes) && (
            <CSSAccordion
              header={<SidenavCollapse name={t(name)} icon={icon} noCollapse={noCollapse} />}
              content={
                <div style={{ marginLeft: 10, overflow: "hidden" }}>
                  {items.map(({ name, icon, key, route, requiredScopes }) => (
                    <PermissionsGate key={key} requiredScopes={requiredScopes}>
                      <NavLink key={name} to={route}>
                        <SidenavCollapse
                          name={<div style={{ marginLeft: -10 }}>{t(name)}</div>}
                          icon={<>-</>}
                          active={key === collapseName}
                        />
                      </NavLink>
                    </PermissionsGate>
                  ))}
                </div>
              }
              itemsLength={items?.length}
            />
          )
        ) : (
          <PermissionsGate key={key} requiredScopes={requiredScopes}>
            <NavLink to={route}>
              <SidenavCollapse name={t(name)} icon={icon} active={key === collapseName} />
            </NavLink>
          </PermissionsGate>
        );
      }
      // else if (type === "title") {
      //   returnValue = (
      //     <MDTypography
      //       key={key}
      //       color={textColor}
      //       display="block"
      //       variant="caption"
      //       fontWeight="bold"
      //       textTransform="uppercase"
      //       pl={3}
      //       mt={2}
      //       mb={1}
      //       ml={1}
      //     >
      //       {title}
      //     </MDTypography>
      //   );
      // }
      else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={0} pb={1} px={4} textAlign="center">
        {/* <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <Link to="/">
          <img
            src={brand}
            className="logo"
            style={{
              filter: "brightness(0) invert(1)",
            }}
          />
        </Link> */}
      </MDBox>
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
