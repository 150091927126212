import { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function ScrollDialog({
  title,
  actionBtnTitle,
  children,
  isOpen,
  handleClose,
  handleDoAction,
  fullScreen,
  titleStyle = {},
  actionStyle = {},
  ...rest
}) {
  const { t } = useTranslation();
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        {...rest}
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
      >
        <DialogTitle style={titleStyle} id="scroll-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }} dividers={scroll === "paper"}>
          {children}
        </DialogContent>
        <DialogActions sx={actionStyle}>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={handleDoAction}>{actionBtnTitle}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
