import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

const DataObjForm = ({ dataObj, setDataObj }) => {
  const { t } = useTranslation();
  return (
    <MDBox mx={1}>
      <Grid
        container
        columnSpacing={4}
        rowSpacing={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item ls={6} xs={12}>
          <MDInput
            autoFocus
            value={dataObj.firstName}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                firstName: e.target.value,
              }))
            }
            margin="dense"
            id="user-first-name"
            label={t("firstName")}
            type="text"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item ls={6} xs={12}>
          <MDInput
            value={dataObj.lastName ? dataObj.lastName : ""}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                lastName: e.target.value,
              }))
            }
            margin="dense"
            id="user-last-name"
            label={t("lastName")}
            type="text"
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={4}
        rowSpacing={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item ls={6} xs={12}>
          <MDInput
            value={dataObj.userName}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                userName: e.target.value,
              }))
            }
            margin="dense"
            id="user-user-name"
            label={t("username")}
            type="text"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item ls={6} xs={12}>
          <MDInput
            value={dataObj.email}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                email: e.target.value,
              }))
            }
            margin="dense"
            id="user-email"
            label={t("email")}
            type="text"
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="space-between" alignItems="center">
        <MDInput
          value={dataObj.phoneNumber ? dataObj.phoneNumber : ""}
          onChange={(e) =>
            setDataObj((oldForms) => ({
              ...oldForms,
              phoneNumber: e.target.value,
            }))
          }
          margin="dense"
          id="user-phone-number"
          label={t("phoneNumber")}
          helperText={`${t("ex")}: +9710000000000`}
          type="text"
          fullWidth
          variant="standard"
        />
      </Grid>
    </MDBox>
  );
};

export default DataObjForm;
