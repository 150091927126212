import { userPermissions } from "./permission-maps";

export const hasPermission = (requiredScopes) => {
  //does user permissions contain all required scopes for this component?
  return requiredScopes.every((scope) => {
    return userPermissions?.indexOf(scope) >= 0 ? true : false;
  });
};

export const hasOnePermission = (requiredScopes) => {
  //does user permissions contain all required scopes for this component?
  return requiredScopes.some((scope) => {
    return userPermissions?.indexOf(scope) >= 0 ? true : false;
  });
};

export default function PermissionsGate({ children, requiredScopes = [], notAuthComponent = "" }) {
  const permissionGranted = hasPermission(requiredScopes);
  if (!permissionGranted) return <>{notAuthComponent}</>;

  return <>{children}</>;
}
