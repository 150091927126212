import { Skeleton } from "@mui/material";
import colors from "assets/theme/base/colors";

const TableLoader = ({ variant }) => {
  return (
    <div style={{ width: "100%", margin: "auto" }}>
      {variant !== "tree" && <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={60} />}
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
      <Skeleton sx={{ bgcolor: `${colors.primary.main}11` }} height={80} />
    </div>
  );
};

export default TableLoader;
