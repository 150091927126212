import MDPagination from "components/MDPagination";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";

import { Icon } from "@mui/material";

export default function Pagination({
  setPage,
  totalPages,
  currentPage,
  /*   paginationCountBoundary = 4, */
}) {
  const { i18n } = useTranslation();

  function handlePageChnage(direction) {
    if (direction === "next") setPage((oldCurrentPage) => oldCurrentPage + 1);
    else setPage((oldCurrentPage) => oldCurrentPage - 1);
  }

  const pagesArray = [];
  for (let i = 1; i <= totalPages; i++) {
    pagesArray.push(i);
  }

  return (
    <MDBox>
      {totalPages > 1 && (
        <MDPagination
          style={{
            flexWrap: "wrap !important",
            padding: "0 14px !important",
          }}
          variant="gradient"
        >
          {currentPage !== 1 && (
            <MDPagination
              item
              onClick={() => handlePageChnage("previous")}
              style={{
                transform: i18n.language === "ar" ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <Icon>keyboard_arrow_left</Icon>
            </MDPagination>
          )}

          {currentPage >= 1 && currentPage <= 4
            ? pagesArray
                .map((page) => (
                  <MDPagination
                    onClick={() => setPage(page)}
                    key={page}
                    item
                    active={currentPage === page ? true : false}
                  >
                    {page}
                  </MDPagination>
                ))
                .filter((component) => component.props.children < 8)
            : currentPage <= totalPages && currentPage >= totalPages - 4
            ? pagesArray
                .map((page) => (
                  <MDPagination
                    onClick={() => setPage(page)}
                    key={page}
                    item
                    active={currentPage === page ? true : false}
                  >
                    {page}
                  </MDPagination>
                ))
                .filter((component) => component.props.children > totalPages - 8)
            : pagesArray
                .map((page) => (
                  <MDPagination
                    onClick={() => setPage(page)}
                    key={page}
                    item
                    active={currentPage === page ? true : false}
                  >
                    {page}
                  </MDPagination>
                ))
                .filter(
                  (component) =>
                    component.props.children <= currentPage + 4 &&
                    component.props.children >= currentPage - 4
                )}
          {currentPage !== totalPages && (
            <MDPagination
              item
              onClick={() => handlePageChnage("next")}
              style={{ transform: i18n.language === "ar" ? "rotate(180deg)" : "rotate(0deg)" }}
            >
              <Icon>keyboard_arrow_right</Icon>
            </MDPagination>
          )}
        </MDPagination>
      )}
    </MDBox>
  );
}
