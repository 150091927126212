import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

const DataObjForm = ({ dataObj, setDataObj }) => {
  const { t } = useTranslation();
  return (
    <MDBox mx={1}>
      <Grid container display="flex" justifyContent="space-between" alignItems="center">
        <MDInput
          autoFouce
          value={dataObj.oldPassword}
          onChange={(e) =>
            setDataObj((oldForms) => ({
              ...oldForms,
              oldPassword: e.target.value,
            }))
          }
          margin="dense"
          id="old-passwrod"
          label={t("old_pass")}
          type="password"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid
        container
        columnSpacing={4}
        rowSpacing={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item ls={6} xs={12}>
          <MDInput
            value={dataObj.newPassword}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                newPassword: e.target.value,
              }))
            }
            margin="dense"
            id="new-password"
            label={t("new_pass")}
            type="password"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item ls={6} xs={12}>
          <MDInput
            value={dataObj.confirmPassword}
            onChange={(e) =>
              setDataObj((oldForms) => ({
                ...oldForms,
                confirmPassword: e.target.value,
              }))
            }
            margin="dense"
            id="confirm-new-password"
            label={t("confirm_new_pass")}
            type="password"
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default DataObjForm;
