import "../src/auth/authService";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import "./i18nextInit";

import "../src/util/global.css";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/vendors/font-awesome.css";
import "./assets/css/vendors/svg-icon.css";
import "./assets/css/linearicon.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

toast.configure();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
