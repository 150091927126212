import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { editProfile, editPassword, withdraw, getBalance } from "./service";
import { object, string, number } from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getUser, setUser } from "auth/authService";
import { useTranslation } from "react-i18next";
import colors from "assets/theme/base/colors";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import {
  List,
  Popover,
  ListItem,
  Icon,
  IconButton,
  Toolbar,
  AppBar,
  CircularProgress,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";

// Material Dashboard 2 My components
import FormModal from "components/FormModal";
import ProfileDataForm from "./UpdateProfileDateForm";
import PasswordDataForm from "./UpdatePasswordDataForm";
import WithdrawDataForm from "./WithdrawRequestDataForm";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import LoadingScreen from "react-loading-screen";

function DashboardNavbar({ absolute, light, isMini }) {
  const { t } = useTranslation();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, currency } =
    controller;
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  /* User menu Start */
  //menu logic
  const [menuAnchor, setMenuAnchor] = useState(null);

  //edit profile
  const editProfileSchema = object({
    firstName: string().required(`${t("firstName")} ${t("required_message")}`),
    userName: string().required(`${t("userName")} ${t("required_message")}`),
    email: string()
      .required(`${t("email")} ${t("required_message")}`)
      .email(t("error_email_invalid")),
    phoneNumber: string()
      .test("is-valid-phone-number", t("error_phoneNumber_invalid"), (phoneNumber) =>
        /^(\+[0-9]{10,13})$/.test(phoneNumber.trim())
      )
      .required(`${t("email")} ${t("required_message")}`),
  });

  const currentProfileData = getUser();

  const [profileDataObj, setProfileDataObj] = useState(currentProfileData);

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  function handleEditProfileModalOpen() {
    setIsEditProfileModalOpen(true);
  }
  const editProfileMutation = useMutation(editProfile, {
    onSuccess: () => {
      toast.success(t("edit_profile_success"));
      setUser(profileDataObj);
    },
    onError: (error) => {
      error.resonse.data[0]
        ? toast.error(error.response.data[0])
        : toast.error(t("unexpected_error"));
    },
  });

  function handleEditProfileDataObj() {
    editProfileSchema
      .validate(profileDataObj)
      .then(() => {
        setIsEditProfileModalOpen(false);
        editProfileMutation.mutate(profileDataObj);
      })
      .catch((error) => {
        error.errors && error.errors.length > 0
          ? toast.error(error.errors[0])
          : toast.error(t("unexpected_error"));
      });
  }

  //edit password
  const [passwordDataObj, setPasswordDataObj] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const passwordSchema = (name) =>
    string()
      .min(5, `${name} ${t("change_pass_error_length")}`)
      .required(`${name} ${t("required_message")}`);
  const editPasswordSchema = object({
    oldPassword: passwordSchema(t("old_pass")),
    newPassword: passwordSchema(t("new_passs")),
    confirmPassword: passwordSchema(t("confirm_new_pass")).test(
      "is-sync",
      t("change_pass_error_new_pass_same_confirm"),
      (confirmPassword) => confirmPassword === passwordDataObj.newPassword
    ),
  });

  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false);
  function handleEditPasswordModalOpen() {
    setIsEditPasswordModalOpen(true);
  }
  const editPasswordMutation = useMutation(editPassword, {
    onSuccess: () => {
      toast.success(t("change_pass_success"));
    },
    onError: (error) => {
      error.resonse.data[0]
        ? toast.error(error.response.data[0])
        : toast.error(t("unexpected_error"));
    },
  });

  function handleEditPasswordDataObj() {
    editPasswordSchema
      .validate(passwordDataObj)
      .then(() => {
        setIsEditPasswordModalOpen(false);
        editPasswordMutation.mutate(passwordDataObj);
      })
      .catch((error) => {
        error.errors && error.errors.length > 0
          ? toast.error(error.errors[0])
          : toast.error(t("unexpected_error"));
      });
  }
  //logout
  const navigate = useNavigate();
  /* User menu end */

  /* Vendor menu start */
  //menu logic
  const [withdrawMenuAnchor, setWithdrawMenuAnchor] = useState(null);
  //get balance
  const {
    data: balanceReq,
    status: balanceStatus,
    refetch: refetchBalance,
  } = useQuery("get-balance", getBalance);

  //vendor withdraw
  const initWithdrawData = {
    withdrawAmount: 1,
    withdrawBank: {
      accountHolderName: "",
      bankName: "",
      city: "",
      swiftCode: "",
      accountNumber: "",
      iban: "",
    },
    withdrawPayPal: null,
  };
  const [withdrawData, setWithdrawData] = useState(initWithdrawData);

  const withdrawSchema = object({
    withdrawAmount: number().required(t("send_withdraw_error_amount")),
    withdrawPayPal: object({
      name: string().required(t("send_withdraw_error_paypal_name")),
      email: string()
        .required(t("send_withdraw_error_email_required"))
        .email(t("error_email_invalid")),
      phoneNumber: string()
        .test("is-valid-phone-number", t("error_phoneNumber-invalid"), (phoneNumber) =>
          /^(\+[0-9]{10,13})$/.test(phoneNumber.trim())
        )
        .required(t("send_withdraw_error_phoneNumber_required")),
    })
      .nullable()
      .test(
        "one-payment-method-at-least",
        t("send_withdraw_error_one_payment_method_required"),
        (paypal) => withdrawData.withdrawBank || paypal
      ),
    withdrawBank: object({
      accountHolderName: string().required(
        `${t("send_withdraw_data_bank_account_holder_name")} ${t("required_message")}`
      ),
      bankName: string().required(
        `${t("send_withdraw_data_bank_bank_name")} ${t("required_message")}`
      ),
      city: string().required(`${t("send_withdraw_data_bank_city")} ${t("required_message")}`),
      swiftCode: string().required(
        `${t("send_withdraw_data_bank_swift_code")} ${t("required_message")}`
      ),
      accountNumber: string().required(
        `${t("send_withdraw_data_bank_account_number")} ${t("required_message")}`
      ),
      iban: string().required(`${t("send_withdraw_data_bank_iban")} ${t("required_message")}`),
    })
      .nullable()
      .test(
        "just-one-payment-method",
        t("send_withdraw_error_just_one_payment_method"),
        (bank) => !(withdrawData.withdrawPayPal && bank)
      ),
  });

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  function handleWithdrawModalOpen() {
    setIsWithdrawModalOpen(true);
  }
  const withdrawMutation = useMutation(withdraw, {
    onSuccess: () => {
      toast.success(t("send_withdraw_success"));
    },
    onError: (error) => {
      error.resonse.data[0]
        ? toast.error(error.response.data[0])
        : toast.error(t("unexpected_error"));
    },
  });

  function handleWithdrawDataObj() {
    withdrawSchema
      .validate(withdrawData)
      .then(() => {
        setIsWithdrawModalOpen(false);
        withdrawMutation.mutate(withdrawData);
      })
      .catch((error) => {
        error.errors && error.errors.length > 0
          ? toast.error(error.errors[0])
          : toast.error(t("unexpected_error"));
      });
  }

  function handleTogglePaymentMethod(status, target) {
    if (!status) {
      setWithdrawData((oldConfig) => ({ ...oldConfig, [target]: null }));
      return;
    }

    let configData = null;
    switch (target) {
      case "withdrawPayPal":
        configData = {
          name: "",
          email: "",
          phoneNumber: "",
        };
      case "withdrawBank":
        configData = {
          accountHolderName: "",
          bankName: "",
          city: "",
          swiftCode: "",
          accountNumber: "",
          iban: "",
        };
    }
    setWithdrawData((oldConfig) => ({ ...oldConfig, [target]: configData }));
  }
  /* Vendor menu end */

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar
          sx={{
            ...(theme) => navbarContainer(theme),
            display: "flex !important",
            justifyContent: "flex-end !important",
            alignItems: "center !important",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={{
              ...(theme) => navbarRow(theme, { isMini }),
              flexGrow: 1,
              marginBottom: "0 !important",
            }}
          >
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>
          {isMini ? null : (
            <MDBox
              sx={{
                ...(theme) => navbarRow(theme, { isMini }),
                whiteSpace: "nowrap",
              }}
            >
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  onClick={({ currentTarget }) => setWithdrawMenuAnchor(currentTarget)}
                  sx={navbarIconButton}
                  size="small"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>store</Icon>
                </IconButton>
                <IconButton
                  onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
                  sx={navbarIconButton}
                  size="small"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
      {/* User Menu  */}
      <Popover
        open={menuAnchor ? true : false}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem
            sx={{ py: 0.7, px: 1.5 }}
            button
            onClick={() => {
              handleEditProfileModalOpen();
              setMenuAnchor(null);
            }}
          >
            {t("edit_profile_data_title")}
          </ListItem>
          <ListItem
            sx={{ py: 0.7, px: 1.5 }}
            button
            onClick={() => {
              handleEditPasswordModalOpen();
              setMenuAnchor(null);
            }}
          >
            {t("change_pass")}
          </ListItem>
          <ListItem
            sx={{ py: 0.7, px: 1.5 }}
            button
            onClick={() => {
              navigate("/logout");
              setMenuAnchor(null);
            }}
          >
            {t("logout")}
          </ListItem>
        </List>
      </Popover>
      {/* Vendor Menu  */}
      <Popover
        open={withdrawMenuAnchor ? true : false}
        anchorEl={withdrawMenuAnchor}
        onClose={() => setWithdrawMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <>
          {balanceStatus === "success" && (
            <>
              <MDBox
                bgColor="primary"
                pb={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={20}
                pt={1}
                px={1.2}
                mb={1}
                mt={0.5}
              >
                <Icon sx={{ color: "#fff !important" }} fontSize="large">
                  account_balance_wallet
                </Icon>
                <MDTypography color="light" mx={1}>
                  {(balanceReq.balance.vendorBalance * currency.rate).toFixed(2)} {currency.isoCode}
                </MDTypography>
              </MDBox>
              <List>
                <ListItem
                  sx={{ py: 0.7, px: 1.5 }}
                  button
                  onClick={() => {
                    handleWithdrawModalOpen();
                    setWithdrawMenuAnchor(null);
                  }}
                >
                  {t("send_withdraw_title")}
                </ListItem>
              </List>
              <Link to="/your-withdraw-requests" style={{ color: "inherit" }} target="_blank">
                <List>
                  <ListItem
                    sx={{ py: 0.7, px: 1.5 }}
                    button
                    onClick={() => {
                      setWithdrawMenuAnchor(null);
                    }}
                  >
                    {t("your_withdraw_title")}
                  </ListItem>
                </List>
              </Link>
            </>
          )}
          {balanceStatus === "loading" && <Loader />}
        </>
      </Popover>
      {/* Withdraw Dialog */}
      <FormModal
        title={
          <MDBox gap={2} display="flex" justifyContent="space-between" alignItems="center">
            <div>{t("send_withdraw_data_title")}</div>
            <div>
              {(balanceReq?.balance?.vendorBalance * currency.rate).toFixed(2)} {currency.isoCode}
            </div>
          </MDBox>
        }
        maxWidth="sm"
        fullWidth
        actionBtnTitle={t("send_withdraw_data_action_reuest")}
        isOpen={isWithdrawModalOpen}
        handleDoAction={handleWithdrawDataObj}
        handleClose={() => setIsWithdrawModalOpen(false)}
      >
        <WithdrawDataForm
          handleTogglePaymentMethod={handleTogglePaymentMethod}
          dataObj={withdrawData}
          setDataObj={setWithdrawData}
        />
      </FormModal>
      {/* Edit Profile Dialog */}
      <FormModal
        title={t("edit_profile_data_title")}
        actionBtnTitle={t("edit")}
        isOpen={isEditProfileModalOpen}
        handleDoAction={handleEditProfileDataObj}
        handleClose={() => setIsEditProfileModalOpen(false)}
      >
        <ProfileDataForm dataObj={profileDataObj} setDataObj={setProfileDataObj} />
      </FormModal>
      {/* Edit Password Dialog */}
      <FormModal
        title={t("change_pass")}
        actionBtnTitle={t("edit")}
        isOpen={isEditPasswordModalOpen}
        handleDoAction={handleEditPasswordDataObj}
        handleClose={() => setIsEditPasswordModalOpen(false)}
      >
        <PasswordDataForm dataObj={passwordDataObj} setDataObj={setPasswordDataObj} />
      </FormModal>
      <LoadingScreen
        loading={
          withdrawMutation.isLoading ||
          editProfileMutation.isLoading ||
          editPasswordMutation.isLoading
        }
        bgColor="#FFFFFFAA"
        spinnerColor={colors.primary.main}
        textColor="#676767"
        children=""
      />
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

const Loader = () => {
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" height={100} width={200}>
      <CircularProgress size={30} color="secondary" />
    </MDBox>
  );
};
