import attributesEn from "pages/attributes/Locales/en.json";
import categoriesEn from "pages/categories/Locales/en.json";
import brandsEn from "pages/brands/Locales/en.json";
import tagsEn from "pages/tags/Locales/en.json";
import navBarEn from "examples/Navbars/DashboardNavbar/Locales/en.json";
import DashboardConfigurationEn from "examples/Configurator/Locales/en.json";
import authEn from "auth/Locales/en.json";
import reportEn from "pages/reports/Locales/en.json";
import orderEn from "pages/orders/Locales/en.json";
import couponEn from "pages/coupons/Locales/en.json";
import withdrawEn from "pages/withdraw requests/Locales/en.json";
import supplierEn from "pages/suppliers/Locales/en.json";
import productEn from "pages/products/Locales/en.json";
import userEn from "pages/users/Locales/en.json";
import vendorEn from "pages/vendors/Locales/en.json";
import roleEn from "pages/roles/Locales/en.json";
import ticketEn from "pages/tickets/Locales/en.json";
import affiliateEn from "pages/affiliate/Locales/en.json";
import returnEn from "pages/return requests/Locales/en.json";
import pagesEn from "pages/pages/locales/en.json";

//ENGLISH
export default {
  dashboard: "Dashboard",
  main_slider: "Main Slider",
  requests: "Requests",
  emails: "Emails",
  coupons: "Coupons",
  cancel: "Cancel",
  add: "Add",
  edit: "Edit",
  delete: "Delete!",
  yes: "Yes",
  no: "No",
  dimensions: "Dimensions",
  size: "Size",
  title: "Title",
  Option: "Options",
  name: "Name",
  email: "Email",
  phoneNumber: "Phone Number",
  ex: "ex",
  firstName: "First Name",
  lastName: "Last Name",
  username: "Username",
  pass: "Password",
  confirm_pass: "Confirm Password",
  error_email_invalid: "Email should be a valid email.",
  error_phoneNumber_invalid: "Phone Number should be valid.",
  add_main_image: "Add Main Image",
  add_gallery_image: "Add Gallery Image",
  no_data: "There is no data to display.",
  unexpected_error: "An UnexpectedError Occured!",
  required_message: "is a required field.",
  delete_message: "Are You Sure You Want To Delete",
  all: "All",
  waiting_confirmation: "Waiting Confirmation",
  under_processing: "Under Processing",
  ready_for_pickup: "Ready For Pickup",
  out_for_delivery: "Out For Delivery",
  customer_received: "Customer Received",
  on_hold: "On Hold",
  canceled: "Canceled",
  returned: "Returned",
  code: "Code",
  from: "From",
  completed: "Completed",
  to: "To",
  type: "Type",
  allowed: "Allowed",
  not_allowed: "Not Allowed",
  coupon_applies_for_cart: "CouponAppliesForCart",
  coupon_applies_for_cart_except_discounted_item: "CouponAppliesForCartExceptDiscountedItem",
  coupon_applies_for_cart_if_one_item_has_discount: "CouponNotAppliedOnCartIfOneItemHasDiscount",
  unit: "Unit",
  apply: "Apply",
  error: "Error!",
  category_image: "Category Image",
  slider_image: "Slider Image",
  main_image: "Main Image",
  gallery_image: "Gallery Image",
  error_occurred: "Error Occurred",
  page: "page",
  of: "of",
  type_to_search: "Type to search",
  new: "New",
  ...attributesEn,
  ...categoriesEn,
  ...brandsEn,
  ...tagsEn,
  ...navBarEn,
  ...DashboardConfigurationEn,
  ...authEn,
  ...reportEn,
  ...orderEn,
  ...couponEn,
  ...withdrawEn,
  ...supplierEn,
  ...productEn,
  ...userEn,
  ...vendorEn,
  ...roleEn,
  ...ticketEn,
  ...affiliateEn,
  ...returnEn,
  ...pagesEn,
  /* settings */
  pages: "Pages",
  page_success_edit: "Page edited successfully.",
  content_in_english: "Content in english",
  content_in_arabic: "Content in arabic",
  update: "Update",

  zones: "Zones",
  zone_success_add: "Zone added successfully.",
  zone_success_edit: "Zone edited successfully.",
  zone_success_delete: "Zone deleted successfully.",
  zone_error_name_en_missing: "You must add zone name in english langauge.",
  zone_error_name_ar_missing: "You must add zone name in arabic langauge.",
  zone_error_fee_greeter_than_zero: "Fee must be bigger than or equal to 0.",
  zone_error_city_en_missing: "City name in english language is a required field.",
  zone_error_city_ar_missing: "City name in arabic language is a required field.",
  fee: "Fee",
  cities: "Cities",
  cities_for: "Cities for",
  add_zone: "Add Zone",
  edit_zone: "Edit Zone",
  delivery_fee: "Delivery Fee",
  zone_accept_cod: "Zone accept cash on delivery",
  zone_cities: "Zone cities",
  add_city: "Add city",
  add_another_city: "Add another city",

  email_success_edit: "Email edited successfully.",
  message: "Message",
  edit_email: "Edit Email",

  currencies: "Currencies",
  currencies_title: "Supported Currencies List",
  currency_success_add: "Currency added successfully.",
  currency_success_deleted: "Currency deleted successfully.",
  currency_code: "Currency Code",
  select_currency: "Select Currency",

  tax: "Tax",
  tax_success_edit: "Tax edited successfully.",
  tax_error_value: "tax must be bigger than 0.",
  inactive: "Inactive",
  edit_tax: "Edit Tax",

  main_slider_title: "Main Slider Images List",
  image: "Image",
  add_slide: "Add Slide",
  add_slide: "Edit Slide",
  slide_success_add: "Slide added successfully.",
  slide_success_edit: "Slide edit successfully.",
  slide_success_delete: "Slide deleted successfully.",
  this_slide: "This Slide",
  slide_order: "Slide Order",

  configs: "Configurations",
  config_error_get: "An error occurred while getting the configurations.",
  retry: "Retry",
  config_success_update: "Configurations updated successfully.",
  update_configs: "Update Configurations",
  min_withdraw: "Minimum Withdrawal Amount Allowed for Vendors",
  contact_info: "Contact Information",
  nav_bar: "Navigation Bar",
  supplier_feature: "Supplier Feature",
  offers_nav_name: "Offers Nav Bar Name",
  todays_deals_nav_name: "Today's Deals Nav Bar Name",
  first_phoneNumber: "First Phone Number",
  second_phoneNumber: "Second Phone Number",
  address: "Address",
  trn: "Tax Registration Number (TRN)",

  //dashboard
  than_last_weak: "than last weak",
  sold_items: "Sole Items",
  sales: "Sales",
  latest_registered_users: "Latest Registered Users",
  latest_orders: "Latest Orders",
};
