import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const Loader = () => {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP - 150}
    >
      <CircularProgress size={30} color="secondary" />
    </MDBox>
  );
};

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 50;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      padding: "0 10px 16px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "fit-content",
      outline: "1px solid #333",
      minWidth: "min(90% ,224px)",
      maxWidth: "min(90% ,320px)",
    },
  },
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function DataObjForm({
  selectedCurrencyCode,
  handleCurrencyChange,
  currenciesStatus,
  availableCurrencies,
  refetchCurrencies,
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  //Set AED if it's not exist
  availableCurrencies = useMemo(() => {
    if (availableCurrencies) {
      const newCurrencies = [...availableCurrencies];

      const isAEDExist = newCurrencies.find((currency) => currency.isoCode === "AED");

      if (!isAEDExist) return [{ isoCode: "AED", rate: 1 }, ...newCurrencies];

      return newCurrencies;
    }

    return availableCurrencies;
  }, [availableCurrencies]);

  return (
    <FormControl px={5} sx={{ minWidth: 170 }}>
      <InputLabel id="search-select-label">{t("dashboard_currency")}</InputLabel>
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={MenuProps}
        labelId="search-select-label"
        id="search-select"
        value={availableCurrencies ? selectedCurrencyCode : "AED"}
        label={t("dashboard_currency")}
        variant="standard"
        onOpen={refetchCurrencies}
        onChange={(e) => {
          handleCurrencyChange(e.target.value);
        }}
        onClose={() => setSearchText("")}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={() => selectedCurrencyCode}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            label={t("type_to_search")}
            fullWidth
            sx={{ mb: 2, mt: 4, input: { color: "#333" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {currenciesStatus === "success" &&
          availableCurrencies
            .filter((currency) => containsText(currency.isoCode, searchText))
            .map((currency) => (
              <MenuItem key={currency.id} value={currency.isoCode}>
                {currency.isoCode}
              </MenuItem>
            ))}

        {currenciesStatus === "error" && (
          <MDTypography pt={2} textAlign="center">
            {t("no_data")}
          </MDTypography>
        )}
        {currenciesStatus === "loading" && <Loader />}
      </Select>
    </FormControl>
  );
}
