/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import i18next from "i18next";
import http from "util/httpService";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

// @mui material components
import { Icon, Switch, Divider, ButtonGroup, Button } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setCurrency,
} from "context";

//my components
import SelectCurrency from "./SelectCurrency";

function Configurator() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    currency,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  /* language start*/
  const selected = localStorage.getItem("i18nextLng") || "en";
  const languageMap = {
    en: { label: "English", dir: "ltr", active: true },
    ar: { label: "العربية", dir: "rtl", active: false },
  };
  const [selectedState, setSelectedState] = useState(selected);
  useEffect(() => {
    document.body.style.direction = languageMap[selectedState.slice(0, 2)]?.dir
      ? languageMap[selectedState.slice(0, 2)]?.dir
      : "ltr";
    http.changeLangRespons(selectedState);
    queryClient.invalidateQueries({ active: true });
  }, [selectedState]);
  /* language end*/

  /* Currency Start */
  const {
    status: currenciesStatus,
    data: currencies,
    refetch: refetchCurrencies,
  } = useQuery(["currencies"], getCurrencies, {
    cacheTime: 10 * 1000, //10min
    staleTime: 5 * 60 * 1000, //5min
    enabled: false,
    onSuccess: (currencies) => {
      const result = currencies.find((c) => c.isoCode === currency.isoCode);
      if (!result) handleCurrencyChange("AED");
    },
    onError: (error) => {
      if (error.response.data?.length !== 0)
        error.isExpectedError
          ? toast.error(error.response.data[0])
          : toast.error("An Unexpected Error Occurred!");
    },
  });
  const handleCurrencyChange = (currencyCode) => {
    //finde currency
    const selectedCurrency = currencies?.find((currency) => currency.isoCode === currencyCode) || {
      isoCode: "AED",
      rate: 1,
    };

    localStorage.setItem("currency", JSON.stringify(selectedCurrency));
    setCurrency(dispatch, selectedCurrency);
  };
  /* Currency end */

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">{t("configurations")}</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      {/* <Divider /> */}

      <MDBox pt={4} pb={4} px={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">{t("navbar_fixed")}</MDTypography>
          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="h6">{t("lang")}</MDTypography>
          <ButtonGroup size="large">
            {Object.keys(languageMap)?.map((item) => (
              <MDButton
                color="settingButton"
                variant={item === selected.slice(0, 2) ? "contained" : "outlined"}
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setSelectedState(item);
                }}
              >
                {languageMap[item].label}
              </MDButton>
            ))}
          </ButtonGroup>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="h6">{t("currency")}</MDTypography>
          <SelectCurrency
            handleCurrencyChange={handleCurrencyChange}
            selectedCurrencyCode={currency.isoCode}
            currenciesStatus={currenciesStatus}
            availableCurrencies={currencies}
            refetchCurrencies={refetchCurrencies}
          />
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;

export function getCurrencies() {
  return http.get("/Currency");
}
