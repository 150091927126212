import http from "util/httpService";

export function editProfile(userData) {
  console.log(userData);
  return http.put("/Users/UpdateMyProfile", userData);
}

export function editPassword(passwordData) {
  console.log(passwordData);
  return http.put("/Users/ChangePassword", passwordData);
}

export function getBalance() {
  return http.get("/Users/profile");
}

export function withdraw(withdrawData) {
  console.log(withdrawData);
  return http.post("/Request/PostWithdrawRequest", withdrawData);
}
