import attributesAr from "pages/attributes/Locales/ar.json";
import categoriesAr from "pages/categories/Locales/ar.json";
import brandsAr from "pages/brands/Locales/ar.json";
import tagsAr from "pages/tags/Locales/ar.json";
import navBarAr from "examples/Navbars/DashboardNavbar/Locales/ar.json";
import DashboardConfigurationAr from "examples/Configurator/Locales/ar.json";
import authAr from "auth/Locales/ar.json";
import reportAr from "pages/reports/Locales/ar.json";
import orderAr from "pages/orders/Locales/ar.json";
import couponAr from "pages/coupons/Locales/ar.json";
import withdrawAr from "pages/withdraw requests/Locales/ar.json";
import supplierAr from "pages/suppliers/Locales/ar.json";
import productAr from "pages/products/Locales/ar.json";
import userAr from "pages/users/Locales/ar.json";
import vendorAr from "pages/vendors/Locales/ar.json";
import roleAr from "pages/roles/Locales/ar.json";
import ticketAr from "pages/tickets/Locales/ar.json";
import affiliateAr from "pages/affiliate/Locales/ar.json";
import returnAr from "pages/return requests/Locales/ar.json";
import pagesAr from "pages/pages/locales/ar.json";

//ARABIC
export default {
  dashboard: "لوحة التحكم",
  main_slider: "السلايدر",
  requests: "المطالب",
  emails: "رسائل البريد الألكتروني",
  coupons: "القسائم",
  cancel: "الغاء",
  add: "اضف",
  edit: "عدل",
  delete: "حذف!",
  yes: "نعم",
  no: "لا",
  dimensions: "الابعاد",
  size: "الحجم",
  title: "العنوان",
  options: "الخيارات",
  name: "الأسم",
  email: "عنوان البريد الألكتروني",
  phoneNumber: "رقم الهاتف",
  ex: "مثال",
  firstName: "الاسم الاول",
  lastName: "القب",
  username: "اسم المستخدم",
  pass: "كلمة السر",
  confirm_pass: "تأكيد كلمة السر",
  error_email_invalid: "عنوان البريد الألكتروني غير صالح.",
  error_phoneNumber_invalid: "رقم الهاتف غير صالح.",
  add_main_image: "اضف الصورة الرئيسية",
  add_gallery_image: "اضف صورة المعرض",
  no_data: "لايوجد بيانات لعرضها.",
  unexpected_error: ".!خطأ غير متوقع قد حدث",
  required_message: "حقل مطلوب.",
  delete_message: "هل انت متأكد انك  تريد ان تحذف",
  all: "الكل",
  on_hold: "في الأنتظار",
  canceled: "الغا",
  returned: "اعاد",
  waiting_confirmation: "انتطار التأكد",
  under_processing: "قيد المعالجة",
  ready_for_pickup: "جاهز للأستلام",
  out_for_delivery: "يوصل",
  customer_received: "وصل للزبون",
  code: "الرمز",
  from: "من",
  completed: "اكملت",
  to: "إلى",
  type: "النوع",
  allowed: "مسموح",
  not_allowed: "غير مسموح",
  coupon_applies_for_cart: "القسيمة تطبق على السلة",
  coupon_applies_for_cart_except_discounted_item:
    "القسمية تطبق على السلة ماعدا عناصر المنتج التي عليها خصم",
  coupon_applies_for_cart_if_one_item_has_discount:
    "القسمية تطبق على السلة اذا كانت القسيمة تحوي على عنصر عليه خصم",
  unit: "الواحدة",
  apply: "طبق",
  error: "خطأ!",
  category_image: "صورة الصنف",
  slider_image: "صورة السلايدر",
  main_image: "الصورة الأساسية",
  gallery_image: "صورة المعرض",
  error_occurred: "حدث خطأ",
  page: "الصفحة",
  of: "من",
  type_to_search: "اكتب لتبحث...",
  new: "جديد",
  ...attributesAr,
  ...categoriesAr,
  ...brandsAr,
  ...tagsAr,
  ...navBarAr,
  ...DashboardConfigurationAr,
  ...authAr,
  ...reportAr,
  ...orderAr,
  ...couponAr,
  ...withdrawAr,
  ...supplierAr,
  ...productAr,
  ...userAr,
  ...vendorAr,
  ...roleAr,
  ...ticketAr,
  ...affiliateAr,
  ...returnAr,
  ...pagesAr,
  /* settings */
  pages: "الصفحات",
  page_success_edit: "الصفحة عدلت بنجاح.",
  content_in_english: "المحتوى بلانجليزي",
  content_in_arabic: "المحتوع بالعربي",
  update: "حدث",

  zones: "المناطق",
  zone_success_add: "المنطقة اضافت بنجاح.",
  zone_success_edit: "المنطقة عدلت بنجاح.",
  zone_success_delete: "المنطقة حذفت بنجاح.",
  zone_error_name_en_missing: "يجب ان تضيف اسم المنطقة بالغة الانجليزي.",
  zone_error_name_ar_missing: "يجب ان تضيف المنطقة بالغة العربية.",
  zone_error_fee_greeter_than_zero: "الرسم يجب ان يكون اكبر او يساوي الصفر.",
  zone_error_city_en_missing: "يجب ان تضيف اسم المدينة بالغة الانجليزية.",
  zone_error_city_ar_missing: "يجب ان تضيف اسم المدينة بالغة العربية.",
  fee: "رسم",
  cities: "المدن",
  cities_for: "المدن ل",
  add_zone: "اضف منطقة",
  edit_zone: "عدل المنطقة",
  delivery_fee: "رسم التوصيل",
  zone_accept_cod: "المنطقة تقبل الدفع عند التوصيل",
  zone_cities: "مدن المنطقة",
  add_city: "اضافت منطقة",
  add_another_city: "اضافت منطقة اخرا",

  email_success_edit: "عدلت الرسالة بنجاح.",
  message: "الرسالة",
  edit_email: "عدل عنوان البريد الألكتروني",

  currencies: "العملات",
  currencies_title: "قائمة العملات المدعومة",
  currency_success_add: "العملة انضافت بنجاح.",
  currency_success_deleted: "العمة انحذفت بنجاح.",
  currency_code: "رمز العملة",
  select_currency: "اختر عملة",

  tax: "الضريبة",
  tax_success_edit: "الضريبة عدلت بنجاح.",
  tax_error_value: "الضريبة يجب ان تكون اكبر من الصفر.",
  inactive: "غير مفعل",
  edit_tax: "عدل الضريبة",

  main_slider_title: "قائمة صور السلايدر",
  image: "الصورة",
  add_slide: "اضف صورة",
  edit_slide: "عدل الصورة",
  slide_success_add: "الصورة اضيفت بنجاح.",
  slide_success_edit: "الصورة عدلت بنجاح.",
  slide_success_delete: "الصور حذفت بنجاح.",
  this_slide: "هذه الصورة",
  slide_order: "ترتيب الصورة",

  configs: "الأعدادات",
  config_error_get: "حدث خطأ اثنا جلب الأعدادات",
  retry: "اعادة المحاولة",
  config_success_update: "الاعدادت تحدثت بنجاح.",
  update_configs: "حدث الأعدادات",
  min_withdraw: "اقل كمية سحب مسموح بها للبائع",
  contact_info: "معلومات الأتصال",
  nav_bar: "شريط التنقل",
  supplier_feature: "خاصية المورد",
  offers_nav_name: "اسم العرض في شريط التنقل",
  todays_deals_nav_name: "عروض اليوم في شريط التنقل",
  first_phoneNumber: "رقم الهاتف الأول",
  second_phoneNumber: "رقم الهاتف الثاني",
  address: "العنوان",
  trn: "رقم التسجيل الضريبي (TRN)",

  //dashboard
  than_last_weak: "من الأسبوع الماضي",
  sold_items: "العناصر المباعة",
  sales: "المبيعات",
  latest_registered_users: "أحدث المستخدمين المسجلين",
  latest_orders: "اخر الطلبات",
};
