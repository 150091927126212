import Cookies from "js-cookie";
import http from "../util/httpService";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

export async function login(userName, password, rememberMe, from) {
  try {
    // Login Request
    const LoginReq = await http.post("Authenticate/Login", { userName, password });
    // Decode Token
    const decoded = jwt_decode(LoginReq.token);
    const FutureTime = new Date(decoded.exp * 1000);
    const CurrentTime = new Date();

    if (rememberMe) {
      // get total seconds between the times
      var delta = Math.abs(FutureTime - CurrentTime) / 1000;
      // calculate (and subtract) whole days
      var days = delta / 86400;
      // calculate (and subtract) whole hours
      var hours = Math.ceil(delta / 3600);

      // If Remember Me Store in Cookies
      // Set User Credentials
      Cookies.set("MODUserToken", LoginReq.token, { expires: days });
      Cookies.set("MODUserPermissions", decoded.Claims, { expires: days });
      Cookies.set("MODUser", JSON.stringify(LoginReq.user), { expires: days });
    } else {
      // Not Remember Me Save in Cookie as Session
      // Set User Credentials
      Cookies.set("MODUserToken", LoginReq.token);
      Cookies.set("MODUserPermissions", decoded.Claims);
      Cookies.set("MODUser", JSON.stringify(LoginReq.user));
    }

    // Redirect To Dashboard
    window.location = from;
  } catch (error) {
    error.isExpectedError ? toast.error(error.response.data[0]) : null;
  }
}

/* User credentials functions */
export function getJwt() {
  return Cookies.get("MODUserToken");
}

export function getUser() {
  return JSON.parse(Cookies.get("MODUser"));
}

export function getUserPermissions() {
  return Cookies.get("MODUserPermissions");
}

export function setJwt(token) {
  return Cookies.set("MODUserToken", token);
}

export function setUser(data) {
  return Cookies.set("MODUser", JSON.stringify(data));
}

export function setUserPermissions(permissions) {
  return Cookies.set("MODUserPermissions", permissions);
}

export function removeJwt() {
  return Cookies.remove("MODUserToken");
}

export function removeUser() {
  return Cookies.remove("MODUserToken");
}

export function removeUserPermissions() {
  return Cookies.remove("MODUserPermissions");
}

export default login;
